table {
  &.gunung-api {
    thead {
      background-color: #D9E2F3;
    }

    tbody {
      text-align: center;

      & > tr > td:last-child {
        text-align: left;
      }
      // .awas {
      //   background-color: red;
      //   color: white;
      // }

      // .siaga {
      //   background-color: yellow;
      // }

      // .waspada {
      //   background-color: #F7CBAC;
      // }

      // .normal {
      //   background-color: lightgreen;
      // }
    }
  }
}