ul, ol {
  list-style-position: inside;
  margin-left: 1rem;
  font-weight: 400;
  font-size: 16px;
}

table {
  font-family: 'Inter', sans-serif;
  &.tg {
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      background-color: orange ;
      color: white;
    }

    th {
      border: 1px solid black;
    }

    td {
      border: 1px solid black;
      padding: 8px;
    }
  }
}

figure {
  color: '#cecece';
}
