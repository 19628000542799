* {
  font-family: 'Inter', 'Helvetica', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Signika', sans-serif;
  font-style: normal;
  color: #111210;
}

h1 {
  font-weight: 300;
  font-size: 100px;
  letter-spacing: -1.5px;
  color: #000000;
  line-height: 115px;
}

h2 {
  font-weight: 300;
  font-size: 62px;
  line-height: 77px;
  letter-spacing: -0.5px;
}

h3 {
  font-weight: 400;
  font-size: 50px;
  line-height: 64px;
  color: #000000;
}

h4 {
  font-weight: 400;
  font-size: 35px;
  line-height: 50px;
  letter-spacing: 0.25px;
  color: #000000;
}

h5 {
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  color: #000000;
}

h6 {
  font-weight: 500;
  font-size: 21px;
  line-height: 36px;
  letter-spacing: 0.15px;
  color: #000000;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

table {
  caption {
    caption-side: bottom;
  }

  &,
  th,
  td {
    border: 0.5px solid #000;
    border-spacing: 0px;
  }

  th,
  td {
    padding: 8px;
  }
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #26cc68;
  border: 2px solid #fff;
}
